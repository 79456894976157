.dashboard-tile {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-tile h3 {
    margin-top: 0;
    font-size: 18px;
  }
  
  .dashboard-tile p {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: bold;
  }
  